export function module_solarPower() {
	$(function() {
		solarPowerContents();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on('resize', function() {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function() {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if ( winWidth != winWidth_resized || winHeight != winHeight_resized ) {
					solarPowerContents();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//pjax
		$(document).on('pjax:end', function() {
			solarPowerContents();
		});

		//リサイズ用関数
		function solarPowerContents() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			//--------------------------------------------------------
			// slick setting
			//--------------------------------------------------------
			$(".solarThumb__item a").click(function(e){
				e.preventDefault();
			});

			var slide_main = $(".solarSlider").slick({
				arrows:false,
				slidesToShow: 1,
				centerMode: false,
				asNavFor: ".solarThumb"
			});
			var slide_sub = $(".solarThumb").slick({
				asNavFor: ".solarSlider",
				infinite: true,
				focusOnSelect: true,
				slidesToShow: 5,
				slidesToScroll: 1
			});

			/*var slider = ".solarSlider"; // スライダー
			var thumbnailItem = ".solarThumb__item"; // サムネイル画像アイテム

			// サムネイル画像アイテムに data-index でindex番号を付与
			$(thumbnailItem).each(function(){
				var index = $(thumbnailItem).index(this);
				$(this).attr("data-index",index);
			});

			// スライダー初期化後、カレントのサムネイル画像にクラス「thumbnail-current」を付ける
			// 「slickスライダー作成」の前にこの記述は書いてください。
			$(slider).on('init',function(slick){
				var index = $(".slide-item.slick-slide.slick-current").attr("data-slick-index");
				$(thumbnailItem+'[data-index="'+index+'"]').addClass("thumbnail-current");
			});

			//slickスライダー初期化
			$(slider).slick({
				autoplay: false,
				arrows: false,
				fade: true,
				infinite: false //これはつけましょう。
			});

			//サムネイル画像アイテムをクリックしたときにスライダー切り替え
			$(thumbnailItem).on('click',function(){
				var index = $(this).attr("data-index");
				$(slider).slick("slickGoTo",index,false);
			});

			//サムネイル画像のカレントを切り替え
			$(slider).on('beforeChange',function(event,slick, currentSlide,nextSlide){
				$(thumbnailItem).each(function(){
					$(this).removeClass("thumbnail-current");
				});
				$(thumbnailItem+'[data-index="'+nextSlide+'"]').addClass("thumbnail-current");
			});*/
		};
	});
}

export function module_index() {
	$(function() {
		topContents();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		//--------------------------------------------------------
		// Loading Event
		//--------------------------------------------------------

		$(document).ready(function() {

			var _loading = sessionStorage.getItem('loading');
			//ヘッダー高さを取得
			var headerHeight = $('#header').height();
			if ( !_loading ) {
				$('body.home').addClass('fixed').hide().fadeIn(1500);
				$('.loading').css('height', winHeight);

				$('.loading').delay(1500).fadeOut(1500).queue(function() {

					this.parentNode.removeChild(this);
					sessionStorage.setItem('loading', 1);
					loadingComplete();
				});

			} else {
				loadingComplete();
			}
			function loadingComplete() {
				$('body').removeClass('fixed');
				$('.wrapper').addClass('visible');
				$('.content').css('marginTop', 'auto');
				$('.loading').remove();
				//グリッドを順に表示
				$('.gridContainer .gridArea').each(function(i){
					/*
					$(this).delay(200 * i).animate({
						'opacity': '1',
						'top': '0'
					});
					*/
					var $this = $(this);
					setTimeout(function() {
						$this.addClass('active');
					}, 200 * i);
				});
				//#に移動
				var urlHash = location.hash;
				if(urlHash) {
					$('body, html').stop().scrollTop(0);
					setTimeout(function(){
						var target = $(urlHash);
						var position = target.offset().top - headerHeight;
						$('body,html').stop().animate({scrollTop:position}, 500);
					}, 1000);
				}
			}
		});

		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		$(window).on('resize', function() {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function() {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if ( winWidth != winWidth_resized || winHeight != winHeight_resized ) {
					topContents();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//リサイズ用関数
		function topContents() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			if(window.innerWidth <= 768) {

				$(window).on('load scroll', function () {

					var scrollTop = $(window).scrollTop();
					var bgPosition = scrollTop / 4; //スクロール後のポジションを指定（値を大きくすると移動距離が小さくなる）

					if (bgPosition) {
						$('#topContent').css('background-position', 'center top -' + bgPosition + 'px');
					}
				});

			}
		}
	});
}

// モジュールとなるjsファイルの読み込み
// analytics
import {module_analytics} from './js/googleAnalytics';

// vendors
import {module_jquery_easing} from './js/jquery.easing';
import {module_slick} from './js/slick.min';
import {module_magnific} from './js/jquery.magnific-popup.min';
import {module_jquery_pjax} from './js/jquery.pjax';

// pages
import {module_common} from './js/common';
import {module_index} from './js/index';
import {module_type} from './js/type';
import {module_solarPower} from './js/solar-power';
import {module_pjax} from './js/pjax';


// モジュール先にある関数を実行
module_analytics();

module_jquery_easing();
module_slick();
module_magnific();
module_jquery_pjax();

module_common();
module_index();
module_type();
module_solarPower();
module_pjax();

// Sassファイルの読み込み
//import './sass/style.scss';

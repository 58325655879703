export function module_common() {

	$(function() {
		//--------------------------------------------------------
		// Resize Event
		//--------------------------------------------------------
		commonResize();

		var timer = false;
		var count = 0;
		var winWidth = $(window).width();
		var winHeight = $(window).height();
		var winWidth_resized;
		var winHeight_resized;

		$(window).on('resize', function() {
			// リサイズ後の放置時間が指定ミリ秒以下なら何もしない(リサイズ中に何度も処理が行われるのを防ぐ)
			if (timer !== false) {
				clearTimeout(timer);
			}

			// 放置時間が指定ミリ秒以上なので処理を実行
			timer = setTimeout(function() {
				// リサイズ後のウインドウの横幅・高さを取得
				winWidth_resized = $(window).width();
				winHeight_resized = $(window).height();

				// リサイズ前のウインドウ幅・髙さとリサイズ後のウインドウ幅・髙さが異なる場合
				if ( winWidth != winWidth_resized || winHeight != winHeight_resized ) {
					commonResize();

					// 次回以降使えるようにリサイズ後の幅・髙さをウインドウ幅に設定する
					winWidth = $(window).width();
					winHeight = $(window).height();
				}
			}, 200);
		});

		//共通リサイズイベント
		function commonResize() {
			//timerをクリア、countをリセット
			clearTimeout(timer);
			count = 0;

			//ヘッダー高さを取得
			var headerHeight = $('#header').height();

			//クリックイベント削除
			$('.spMenu__btn, .gNav, .menu-item a').off('click');


			//--------------------------------------------------------
			// メニュー展開
			//--------------------------------------------------------
			var state = false;
			var scrollpos;

			$('.spMenu__btn').on('click', function(event) {
				//event.preventDefault();

				if(state == false) {
					// 背景コンテンツ固定
					scrollpos = $(window).scrollTop();
					$('body').addClass('fixed').css({'top': -scrollpos});
					// グローバルメニュー表示
					$('.gNav').fadeIn(200);
					$('.hTop__hl, .spMenu__btn').addClass('active');
					if ($(this).scrollTop() > headerHeight) {
						$('.content').css('marginTop', headerHeight);
					}
					state = true;
				} else {
					// 背景コンテンツ固定 解除
					$('body').removeClass('fixed').css({'top': 0});
					window.scrollTo( 0 , scrollpos );
					// グローバルメニュー非表示
					$('.gNav').fadeOut(200);
					$('.hTop__hl, .spMenu__btn').removeClass('active');
					state = false;
				}
			});
			//gNav
			$(document).on('click', '.pjax', function(e) {
				var headerMenu = $('#header');
				headerMenu.removeClass('fixedHeader');
				$('body').removeClass('fixed');
				// gNav hide
				$('.gNav').fadeOut(200);
				$('.hTop__hl, .spMenu__btn').removeClass('active');
				state = false;
			});


			//--------------------------------------------------------
			// スクロール後
			//--------------------------------------------------------
			var headerMenu = $('#header');
			var menuBtn = $('.spMenu__btn, .hTop__contact');

			$(window).on('load scroll', function () {

				if ($(this).scrollTop() > headerHeight) {
					headerMenu.addClass('fixedHeader');
					menuBtn.addClass('fixedMenu');
					$('.content').css('marginTop', headerHeight);

				} else {

					$('.content').css('marginTop', 'auto');

					if(!$('body').hasClass('fixed')) {
						headerMenu.removeClass('fixedHeader');
						menuBtn.removeClass('fixedMenu');
					} else {
						$('.content').css('marginTop', headerHeight);
					}

				}
			});


			//--------------------------------------------------------
			// アンカーリンクへスムーズに移動
			//--------------------------------------------------------
			$('a[href*="#"]').not('.pagetop a, .solarThumb__item a').click(function() {
				if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
					var $target = $(this.hash);
					$target = $target.length && $target || $('[name=' + this.hash.slice(1) +']');
					if ($target.length) {
						var targetOffset = $target.offset().top - headerHeight;
						$('body, html').animate({scrollTop: targetOffset}, 1500,'easeInOutQuart');
						return false;
					}
				}
			});

		} //commonResize
	});


	//--------------------------------------------------------
	// PAGETOP
	//--------------------------------------------------------
	var topBtn = $('.pagetop');

	topBtn.hide();

	$(window).scroll(function () {
		if ($(this).scrollTop() > 200) {
			topBtn.fadeIn();
		} else {
			topBtn.fadeOut();
		}
	});

	//スクロールしてトップ
	topBtn.click(function () {
		$('body,html').animate({
			scrollTop: 0
		}, 1500,'easeInOutQuart');
		return false;
	});


	//--------------------------------------------------------
	// Scroll Event
	//--------------------------------------------------------
	const obj = $(".scrollAnimation-obj");
	const upIn = $(".scrollAnimation-up");
	const downIn = $(".scrollAnimation-down");
	const leftIn = $(".scrollAnimation-left");
	const rightIn = $(".scrollAnimation-right");
	const listIn = $(".scrollAnimation-list");

	$(window).on('scroll',function(){
		const scroll = $(window).scrollTop();
		const windowH = $(window).height();

		obj.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				$(this).css({
					'opacity': '1'
				});
			}
		});
		upIn.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});
		downIn.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});
		leftIn.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});
		rightIn.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				$(this).css({
					'opacity': '1',
					'transform': 'translate(0, 0)'
				});
			}
		});
		listIn.each(function(){
			const objPos = $(this).offset().top;
			if(scroll > objPos - windowH + 200){
				var lists = $(this).children();
				lists.each(function(i){
					$(this).delay(100 * i).animate({
						'opacity': '1',
						'transform': 'translate(0, 0)'
					});
				});
			}
		});
	});
}

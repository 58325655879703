export function module_pjax() {
	$(function() {

		// pjax遷移開始
		var nextUrl = '';
		$(document).on('click', '.pjax', function(e) {
			e.preventDefault();

			// 遷移先のURLを取得
			nextUrl = $(this).attr('href');
			$('body,html').stop().animate({scrollTop:0}, 200);
			$('#contents').removeClass('active');
			setTimeout(function() {
				//エフェクトが終わったらPjaxイベント
				$.pjax({
					url: nextUrl,
					container: '#contents', // 入れ替える部分
					fragment: '#contents', // 入れ替え先
					timeout: 20000,
				});
			}, 100);
		});

		//Pjaxイベントが終わったときの動作
		$(document).on('pjax:end', function () {
			pjaxLoaded();

			//Google Analytics
			ga('set', 'location', window.location.href);
			ga('send', 'pageview');
			ga('newTracker.send', 'pageview');

			/*window.dataLayer = window.dataLayer || [];
			window.dataLayer.push({
				'newPageUrl': location.pathname,
				event: 'pageLoaded'
			});*/
		});

		// タイムアウト時
		$(document).on('pjax:timeout', function() {
			location.href = nextUrl;
			pjaxLoaded();
		});

		if ( $('#contents')[0] ) {
			pjaxLoaded();
		}

		function pjaxLoaded() {
			if ( $('.solarHeader')[0] ) {
				var $img = $('.solarHeader img');
				$img.originSrc = $img.attr('src');
				$img.attr('src', '');
				$img.on('load', function(){
					showContents();
				});
				$img.attr('src', $img.originSrc);
			} else {
				showContents();
			}
		};

		function showContents() {
			if ( $('#solarMap')[0] ) {
				initialize(map_lat, map_lon);
			}
			if ( $('#companyNap')[0] ) {
				companyInitialize();
			}


			$('a').each(function(){
				if ( !$(this).attr('target') && $(this).attr('href') != '#') {
					$(this).addClass('pjax');
				}
			});
			var _loading = sessionStorage.getItem('loading');
			if ( _loading ) {
				$('.gridArea').addClass('active');
			}
			$('#contents').addClass('active');
		}
		//--------------------------------------------------------
		// 地図表示
		//--------------------------------------------------------

		var currentWindow = null;
		function initialize(map_lat, map_lon) {
			var myOptions = {
				zoom: 16,
				center: new google.maps.LatLng(map_lat, map_lon),
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				scrollwheel: false
			};
			console.log(map_lat, map_lon);

			var map = new google.maps.Map(document.getElementById("solarMap"),myOptions);
			var markers = [
				['', map_lat, map_lon, '/wp-content/themes/links/images/solar-power/icon_pin_solar.png'],
			];
			for (var i = 0; i < markers.length; i++) {
				var name = markers[i][0];
				var latlng = new google.maps.LatLng(markers[i][1],markers[i][2]);
				var icons = {
					url: markers[i][3],
					scaledSize: new google.maps.Size(45, 50)
				};
				createMarker(latlng,name,map,icons)
			}
		}
		function companyInitialize() {
			console.log('companyInitialize');
			var myOptions = {
				zoom: 16,
				center: new google.maps.LatLng(33.595748, 130.427775),
				mapTypeId: google.maps.MapTypeId.ROADMAP,
				scrollwheel: false
			};

			var map = new google.maps.Map(document.getElementById("companyNap"),myOptions);
			var markers = [
				['', 33.595748, 130.427775,'/wp-content/themes/links/images/company/icon_pin_company.png'],
			];
			for (var i = 0; i < markers.length; i++) {
				var name = markers[i][0];
				var latlng = new google.maps.LatLng(markers[i][1],markers[i][2]);
				var icons = {
					url: markers[i][3],
					scaledSize: new google.maps.Size(45, 50)
				};
				createMarker(latlng,name,map,icons)
			}
		}
		function createMarker(latlng,name,map,icons)	{
			var infoWindow = new google.maps.InfoWindow();
			var marker = new google.maps.Marker({position:latlng, map:map, icon:icons});
			google.maps.event.addListener(marker, 'click', function() {
				if (currentWindow) {
					currentWindow.close();
				}
				infoWindow.setContent(name);
				//infoWindow.open(map,marker); //ウインドウ
				currentWindow = infoWindow;
			});
		}
	});

}

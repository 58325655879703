export function module_type() {
	$(function() {
		//data-hrefの属性を持つtrを選択しclassにclickableを付加
		propertyListTable();
		//pjax
		$(document).on('pjax:end', function() {
			propertyListTable();
		});
		function propertyListTable() {
			$('.propertyListTable tr[data-href]').addClass('clickable').each(function(){
				const href = $(this).attr('data-href');
				$('td', this).append('<a href="' + href + '" class="pjax"></a>');
			});
		};
		//クリックイベント
		$(document).on('click', '.propertyListTable tr[data-href]', function(e) {
				//e.targetはクリックした要素自体、それがa要素以外であれば
				if(!$(e.target).is('a')){
					//その要素の先祖要素で一番近いtrの
					//data-href属性の値に書かれているURLに遷移する
					//window.location = $(e.target).closest('tr').data('href');
				}
		});
	});
}
